import dynamic from 'next/dynamic'
/*
export { default as SoloBannerBlock } from './SoloBannerBlock'
export { default as SoloBrandBlock } from './SoloBrandBlock'
export { default as SoloRecipesBlock } from './SoloRecipesBlock'
export { default as SoloSectionTitleBlock } from './SoloSectionTitleBlock'
export { default as SoloTitleImageBodyBlock } from './SoloTitleImageBodyBlock'
export { default as SoloVideoBlock } from './SoloVideoBlock'
export { default as SoloVideosSliderBlock } from './SoloVideosSliderBlock'
export { default as SoloBodyBlock } from './SoloBodyBlock'
export { default as SoloHistoryContainerBlock } from './SoloHistoryContainerBlock'
export { default as SoloArticleUnionFourItemsBlock } from './SoloArticleUnionFourItemsBlock'
export { default as SoloFullWidthImageCardBlock } from './SoloFullWidthImageCardBlock'
export { default as SoloTitleBodyCtaBlock } from './SoloTitleBodyCtaBlock'
export { default as SoloContactFormBlock } from './SoloContactFormBlock'
export { default as SoloCouponsBlock } from './SoloCouponsBlock'*/

export const SoloBannerBlock = dynamic(() => import('./SoloBannerBlock'))
export const SoloBrandBlock = dynamic(() => import('./SoloBrandBlock'))
export const SoloRecipesBlock = dynamic(() => import('./SoloRecipesBlock'))
export const SoloSectionTitleBlock = dynamic(
  () => import('./SoloSectionTitleBlock')
)
export const SoloTitleImageBodyBlock = dynamic(
  () => import('./SoloTitleImageBodyBlock')
)
export const SoloVideoBlock = dynamic(() => import('./SoloVideoBlock'))
export const SoloVideosSliderBlock = dynamic(
  () => import('./SoloVideosSliderBlock')
)
export const SoloBodyBlock = dynamic(() => import('./SoloBodyBlock'))
export const SoloHistoryContainerBlock = dynamic(
  () => import('./SoloHistoryContainerBlock')
)
export const SoloArticleUnionFourItemsBlock = dynamic(
  () => import('./SoloArticleUnionFourItemsBlock')
)
export const SoloFullWidthImageCardBlock = dynamic(
  () => import('./SoloFullWidthImageCardBlock')
)
export const SoloTitleBodyCtaBlock = dynamic(
  () => import('./SoloTitleBodyCtaBlock')
)
export const SoloContactFormBlock = dynamic(
  () => import('./SoloContactFormBlock')
)
