import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import { Format, SoloContactUsBlock, SoloPage, SoloSocialNetworksBlock } from '../../graphql/generated/api-graphql';
import tracking, { TrackingCategoryType, TrackingContentGroup, TrackingUrlException } from '../../tracking';
import productPopinTransform from '../ProductPopin/transform';
import Router, { routes } from '../../routes/Router';
import { SoloHeroProps } from '../../components/molecules/SoloHero';
import useSoloHero from '../Solo/useSoloHero';
import { SoloNavProps } from '../../components/molecules/SoloNav';
import transformSoloNav from '../Solo/transformSoloNav';
import { SoloAnchorsProps } from '../../components/molecules/SoloAnchors';
import transformSoloAnchors from '../Solo/transformSoloAnchors';
import { SoloHistoryAnchorsProps } from '../../components/molecules/SoloHistoryAnchors';
import transformSoloHistoryAnchors from '../Solo/transformSoloHistoryAnchors';
import { SoloContentAnchorsProps } from '../../components/molecules/SoloContentAnchors';
import transformSoloContentAnchors from '../Solo/transformSoloContentAnchors';
import { SoloBannerProps } from '../../components/molecules/SoloBanner';
import transformSoloBannerBlock from '../BlocksSolo/SoloBannerBlock/transformer';
import { ContactUsBlockProps } from '../../components/molecules/ContactUsBlock';
import { transformSoloContactUsBlock } from '../BlocksSolo/SoloContactUs/transformer';
import { SoloSocialsProps } from '../../components/molecules/SoloSocials';
import { transformSoloSocialNetworksBlock } from '../BlocksSolo/SoloSocialNetworksBlock/transformer';
import { ProductPopinProps } from '../../components/molecules/ProductPopin';
import Blocks from '../BlocksSolo';
import { adPropsTransformer } from '../Common/adPropsTransformer';
import { themes } from '../../theme';
import { keyFromApi } from '../../theme/themes';
import SoloTemplate from '../../templates/SoloTemplate';
import type { BrandPageProps } from '../../pages/_brand/[slug]';
import HeaderWrapper from '../../relay/Header/HeaderWrapper';
import FooterWrapper from '../../relay/Footer/FooterWrapper';
const SoloBrandPage: FC<Omit<BrandPageProps, 'data'> & {
  data: SoloPage;
}> = ({
  data,
  configuration,
  referer
}) => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const [openOpin, setOpenPopin] = useState(false);
  const [format, setFormat] = useState<any>(null);
  useEffect(() => {
    if (!router.asPath.includes(TrackingUrlException.JC_CDD)) {
      tracking.page({
        brand: data.brand.name ?? undefined,
        group: TrackingContentGroup.SOLO_BRAND,
        page: data.menuLinks.find(item => item.isActive)?.title ?? data.title,
        section: TrackingCategoryType.SOLO,
        hideRubrique: true
      });
    }
  }, [data, router.asPath]);
  const popinData = productPopinTransform({
    t,
    data: {
      format: format
    },
    displayMoreButton: false
  });

  // popin
  const handleClose = useCallback(() => {
    setOpenPopin(false);
    setFormat(null);
    router.push(Router.getRouteUrl(routes.brand, {
      slug: data.slug
    }), undefined, {
      shallow: true
    });
  }, [data.slug, router]);
  const handleLoadOpenPopin = useCallback((product: Format) => {
    setFormat(product);
    setOpenPopin(true);
  }, []);

  // filter out blocks that should not be blocks
  const filteredBlocks = data?.blocks?.filter((block, index) => {
    return index === 0 && block.__typename === 'SoloBannerBlock' || block.__typename === 'SoloSocialNetworksBlock' || block.__typename === 'SoloContactUsBlock' ? false : true;
  });

  // hero
  const soloHeroProps: SoloHeroProps | undefined = useSoloHero(data);

  // brand space navigation
  const soloNavProps: SoloNavProps | undefined = transformSoloNav(data, referer);
  // @todo
  // on navigating in between we scroll to the position of the nav (before the nav is sticky)

  // brand products navigation
  const soloAnchorsProps: SoloAnchorsProps | undefined = transformSoloAnchors(data);

  // history navigation
  const soloHistoryAnchorsProps: SoloHistoryAnchorsProps | undefined = transformSoloHistoryAnchors(data);

  // history navigation
  const soloContentAnchorsProps: SoloContentAnchorsProps | undefined = transformSoloContentAnchors(data);

  // Banner appearing sub navigation but still in the top zone
  const soloBannerProps: SoloBannerProps | undefined = useMemo(() => {
    const bannerData = data?.blocks?.[0]?.__typename === 'SoloBannerBlock' ? data?.blocks[0] : undefined;
    return bannerData && bannerData?.image ? transformSoloBannerBlock(bannerData) : undefined;
  }, [data?.blocks]);

  // contact us
  const contactUsProps: ContactUsBlockProps | undefined = useMemo(() => {
    const contactUsData = data?.blocks?.filter(block => block.__typename === 'SoloContactUsBlock')?.[0] ?? undefined;
    return contactUsData ? transformSoloContactUsBlock(t, contactUsData as SoloContactUsBlock) : undefined;
  }, [data?.blocks, t]);

  // socials
  const soloSocialsProps: SoloSocialsProps | undefined = useMemo(() => {
    const socialsData = data?.blocks?.filter(block => block.__typename === 'SoloSocialNetworksBlock')?.[0] ?? undefined;
    return socialsData ? transformSoloSocialNetworksBlock(t, socialsData as SoloSocialNetworksBlock) : undefined;
  }, [data?.blocks, t]);

  // popin
  const productPopinProps: ProductPopinProps | undefined = popinData ? {
    ...popinData,
    popin: {
      ...popinData.popin,
      isOpen: openOpin,
      closeHandler: handleClose
    }
  } : undefined;
  const blocks = filteredBlocks && filteredBlocks.length > 0 ? <Blocks blocks={filteredBlocks} pagesByTemplate={configuration?.pagesByTemplate} popinHandler={handleLoadOpenPopin} brandId={data?.brand?.id} /> : null;
  const adProps = adPropsTransformer(data.brand, configuration);

  // render
  return <ThemeProvider theme={themes[keyFromApi(data.theme)]}>
      <SoloTemplate idContent="Content" header={<HeaderWrapper data={configuration?.header} socials={configuration?.footer?.socialLinks} />} footer={<FooterWrapper data={configuration?.footer} />} soloHeroProps={soloHeroProps} soloNavProps={soloNavProps} soloAnchorsProps={soloAnchorsProps} soloHistoryAnchorsProps={soloHistoryAnchorsProps} soloContentAnchorsProps={soloContentAnchorsProps} soloBannerProps={soloBannerProps} contactUsProps={contactUsProps} soloSocialsProps={soloSocialsProps} productPopinProps={productPopinProps} adProps={adProps}>
        {blocks && <>{blocks}</>}
      </SoloTemplate>
    </ThemeProvider>;
};
export default SoloBrandPage;