import { ActionButtonVariant } from '../../components/atoms/ActionButton/styled'
import { PushFormatsProps } from '../../components/molecules/PushFormats'
import {
  Brand,
  BrandPushFormat,
  Format,
} from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'

export const brandPushFormatsTransformer = (
  t: any,
  data: Brand,
  popinHandler?: (data: Format) => void
): PushFormatsProps[] | undefined => {
  if (!data.pushFormats || data.pushFormats.length === 0) {
    return undefined
  }

  return data.pushFormats.map((pushFormat: BrandPushFormat) => ({
    title: pushFormat.title ?? '',
    imageProps: {
      withHD: true,
      maxWidth: 600,
      width: 600,
      height: 600,
      alt: pushFormat.title ?? ' ',
      images: wediaImagesTransform(pushFormat.image),
    },
    products: pushFormat.formats?.map((format) => ({
      title: format?.title,
      imageProps: {
        withHD: true,
        maxWidth: 170,
        width: 170,
        height: 170,
        alt: format?.title,
        images: wediaImagesTransform(format?.thumbnail ?? format?.packshot),
      },
      actionButtonsProps: [
        {
          label: t('product_more_info'),
          href: format?.slug
            ? Router.getRouteUrl(routes.format, { slug: format.slug })
            : undefined,
          onClick: (e) => {
            e.preventDefault()
            popinHandler?.(format)
          },
          variant: ActionButtonVariant.secondary,
        },
      ],
    })),
  }))
}
