import type { GetServerSidePropsContext, NextPage } from 'next';
import SoloBrandPage from 'src/relay/Brand/SoloBrandPage';
import { isMaintenance, set503Header, setDefaultCacheHeader, setNoCacheHeader, ssrPersistedQuery, transformSeoToMetas } from '../../../helpers/SSRHelpers';
import { initWithLangAndLabels } from '../../../i18n';
import { Brand, Format, SoloPage, Tag } from '../../../graphql/generated/api-graphql';
import brandPage from '../../../graphql/Services/Brand/queries/brandPage';
import Router, { routes } from '../../../routes/Router';
import { Configuration } from '../../../graphql/Services';
import { GetServerSidePropsReturnType, PageProps } from '../../../relay/Common/PageProps';
import format from '../../../graphql/Services/Cheese/queries/format';
import BugsnagHelpers from '../../../helpers/BugsnagHelpers';
import soloPageBySlug from '../../../graphql/Services/Solo/queries/soloPageBySlug';
import RealBrandPage from '../../../relay/Brand/RealBrandPage';
export type BrandPageProps = PageProps & {
  data: Brand | SoloPage;
  tags: Tag[];
  popinProps?: Format;
  isSubPage?: boolean;
  referer?: string;
};
const BrandPage: NextPage<BrandPageProps> = ({
  data,
  tags,
  configuration,
  popinProps,
  ...rest
}) => {
  return data.__typename === 'SoloPage' ? <SoloBrandPage data={data as SoloPage} tags={tags} configuration={configuration} popinProps={popinProps} {...rest} /> : data.__typename === 'Brand' ? <RealBrandPage data={data as Brand} tags={tags} configuration={configuration} popinProps={popinProps} {...rest} /> : null;
};
export default BrandPage;
export async function getServerSideProps(context: GetServerSidePropsContext): GetServerSidePropsReturnType<BrandPageProps> {
  try {
    const slug = `${context?.query?.slug}${context?.query?.slug2 ? `/${context?.query?.slug2}` : ''}`;
    const formatSlug = context?.query?.format;
    const [rs, soloRs, configurationRs, formatRs] = await Promise.all([ssrPersistedQuery(brandPage, {
      slug: slug
    }), ssrPersistedQuery(soloPageBySlug, {
      slug: slug
    }), ssrPersistedQuery(Configuration.queries.configuration), ...(formatSlug ? [ssrPersistedQuery(format, {
      slug: formatSlug
    })] : [])]);
    const tags = rs?.data?.tags ?? null;
    const data = soloRs?.data ?? rs?.data?.brand ?? {};
    const configuration = configurationRs?.data ?? null;
    const i18n = initWithLangAndLabels('fr', {});
    if (!configuration || rs?.errors || soloRs?.errors || formatRs?.errors) {
      set503Header(context);
      return {
        props: {
          i18n,
          error: true
        }
      };
    }
    if (isMaintenance(configuration)) {
      set503Header(context);
    } else {
      setDefaultCacheHeader(context);
    }
    if (!data?.id) {
      return {
        notFound: true,
        props: {
          i18n,
          configuration
        }
      };
    }
    return {
      props: {
        i18n,
        configuration,
        metas: transformSeoToMetas(Router.getRouteUrl(context?.query?.slug2 ? routes.brandSub : routes.brand, {
          slug: context?.query?.slug,
          ...(context?.query?.slug2 ? {
            slug2: context?.query?.slug2
          } : {})
        }), data?.seo),
        tags: tags,
        data,
        ...(formatRs?.data && {
          popinProps: formatRs?.data
        }),
        isSubPage: context?.query?.slug2 ? true : false,
        referer: context?.req.headers.referer ?? null
      }
    };
  } catch (e: any) {
    BugsnagHelpers?.notify(e, event => {
      const {
        resolvedUrl,
        query,
        params
      } = context;
      event.addMetadata('context', {
        resolvedUrl,
        query,
        params
      });
    });
    setNoCacheHeader(context);
    return {
      notFound: true
    };
  }
}