import { FC, useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useDebounce } from 'usehooks-ts';
import { createGlobalStyle } from 'styled-components';
import { LinkProps } from '../../atoms/Link';
import { IconProps } from '../../atoms/Icon';
import { PictureProps } from '../../atoms/Picture';
import * as SC from './styled';
export type SoloNavProps = MinimalSeoBlock & {
  className?: string;
  logo?: PictureProps;
  links?: (LinkProps & {
    iconProps?: IconProps;
  })[];
  offsetSticky?: number;
  referer?: string;
};
const SoloNav: FC<SoloNavProps> = ({
  className,
  links,
  logo,
  offsetSticky = 0,
  referer
}) => {
  const stickyRef = useRef<HTMLDivElement | null>(null);
  const [previousReferer, setPreviousReferer] = useState<string | null>(null);
  const [isScrollable, setScrollable] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const debouncedScrollable = useDebounce<boolean>(isScrollable, 10);
  const scrollRef = useRef<ScrollContainer>(null);
  const addScrollCursor = useCallback(() => {
    const element = scrollRef.current?.getElement();
    if (!element || !element?.children) return;
    const childrenWidth = Array.from(element.children).reduce((total, element) => {
      return total + element.clientWidth;
    }, 0);
    setScrollable(element.offsetWidth < childrenWidth);
  }, []);
  const toggleSticky = useCallback(() => {
    if (!window || !stickyRef) return;
    if ((stickyRef.current?.getBoundingClientRect()?.top ?? 0) + (offsetSticky ?? 0) <= 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, [offsetSticky]);
  useEffect(() => {
    const onResize = () => {
      addScrollCursor();
    };
    const onScroll = () => {
      toggleSticky();
    };
    onScroll();
    onResize();
    window && window.addEventListener('resize', onResize);
    window && window.addEventListener('scroll', onScroll);
    return () => {
      window && window.removeEventListener('resize', onResize);
      window && window.removeEventListener('scroll', onScroll);
    };
  }, [addScrollCursor, toggleSticky]);

  // I removed the router.events.on('routeChangeComplete') handler because it was only working after a first client side navigation
  useEffect(() => {
    if (!links || !referer) return;
    const refererSlug = referer.split('/').slice(3).join('/');
    const hasRefererSlug = links.some(link => link.href?.includes(refererSlug));
    if (hasRefererSlug && referer !== previousReferer) {
      stickyRef.current?.scrollIntoView();
      setPreviousReferer(referer); // introduced to allow inner links in page to work
    }
  }, [links, referer, previousReferer]);

  // @TODO check SSR problems
  // add a scroll-padding to html when this element is on the page
  const GlobalStyle = createGlobalStyle`
    html {
      scroll-padding-block-start: ${78 + 20 + offsetSticky}px;
    }
  `;
  return links && links.length > 0 ? <>
      <GlobalStyle />
      <SC.Stickytrigger aria-hidden ref={stickyRef} />
      <SC.Container className={cx('SoloNav', className)} $isSticky={isSticky} $offset={offsetSticky ?? 0}>
        {logo && <SC.Logo {...logo} $isVisible={isSticky} />}
        <SC.Scroller $scrollable={debouncedScrollable} ref={scrollRef}>
          <SC.Links>
            <SC.Spacer aria-hidden $isVisible={isSticky} />
            {links.map((link, index) => <li key={index}>
                <SC.StyledLink {...link}>
                  {link.text}
                  {link?.iconProps && <SC.StyledIcon width={24} height={24} {...link?.iconProps} />}
                </SC.StyledLink>
              </li>)}
            <SC.Spacer aria-hidden $isVisible={isSticky} />
          </SC.Links>
        </SC.Scroller>
      </SC.Container>
    </> : null;
};
export default SoloNav;