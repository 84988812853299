import React, { FC } from 'react';
import cx from 'classnames';
import * as SC from './styled';
export type AccessibleTitleProps = MinimalSeoBlock & {
  className?: string;
  title: string;
};
const AccessibleTitle: FC<AccessibleTitleProps> = props => {
  const {
    className,
    htmlTag,
    title
  } = props;
  return title ? <SC.AccessibleTitle className={cx('AccessibleTitle', className)} as={htmlTag}>
      {title}
    </SC.AccessibleTitle> : null;
};
export default AccessibleTitle;