import styled, { css } from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

import { mq, sizes } from '../../../theme'
import Link from '../../atoms/Link'
import Picture from '../../atoms/Picture'
import Icon from '../../atoms/Icon'

export const Container = styled.nav`
  padding-block: 4rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 3rem;
  position: relative;
  @media ${mq(sizes.desktop)} {
    padding-block-end: 2rem;
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH2}
  padding-inline: 1.5rem;
  margin: 0;
  text-align: center;
  text-wrap: balance;
  color: ${({ theme }) => theme.scheme.anchorsTitleColor};
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.titleH1}
  }
`
export const Sticky = styled.div<{ $sticky?: boolean; $offset?: number }>`
  ${({ theme, $sticky, $offset }) =>
    $sticky === true
      ? css`
          position: fixed;
          bottom: 0;
          left: 6rem;
          right: 8rem;
          z-index: ${theme.zIndexes.forefront};
          @media ${mq(sizes.desktop)} {
            --position-y__default: 1.5rem;
            --position-y__offset: ${$offset ?? 0}px;
            top: calc(var(--position-y__default) + var(--position-y__offset));
            bottom: auto;
            left: auto;
            right: 1.5rem;
            pointer-events: none;
          }
        `
      : null}
`
export const Scroller = styled(ScrollContainer)<{
  $scrollable?: boolean
  $sticky?: boolean
}>`
  --scrollbar-color--bg: ${({ theme }) => theme.scheme.anchorsScrollbarBGColor};
  --scrollbar-color: ${({ theme }) => theme.scheme.anchorsScrollbarColor};
  --cursor--wrapper: ${({ $scrollable }) =>
    $scrollable === true ? 'grab' : 'initial'};
  --cursor--link: ${({ $scrollable }) =>
    $scrollable === true ? 'inherit' : 'pointer'};
  cursor: var(--cursor--wrapper);
  @media ${mq(sizes.desktop)} {
    &,
    &.indiana-scroll-container--hide-scrollbars {
      padding-block-end: 2rem;
      overflow-x: auto;
      scrollbar-width: thin;
      scrollbar-color: var(--scrollbar-color) var(--scrollbar-color--bg);
    }
  }
  // sticky
  ${({ $sticky }) =>
    $sticky === true &&
    css`
      @media ${mq(sizes.desktop)} {
        &,
        &.indiana-scroll-container--hide-scrollbars {
          padding-block-end: 0;
          overflow: visible;
        }
      }
    `}
`
export const List = styled.ul<{ $sticky?: boolean }>`
  // normal
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  gap: 1.5rem;
  width: fit-content;
  padding: 0;
  margin: 0 auto;
  @media ${mq(sizes.desktop)} {
    margin: 0 auto;
    gap: 3rem;
  }
  // sticky
  ${({ $sticky }) =>
    $sticky === true &&
    css`
      gap: 1rem;
      margin: 0;
      @media ${mq(sizes.desktop)} {
        flex-flow: column nowrap;
        align-items: flex-end;
        gap: 1rem;
        margin: 0;
      }
    `}
`
export const Item = styled.li<{ $sticky?: boolean }>`
  // normal
  display: flex;
  flex-flow: column;
  align-items: stretch;
  min-width: min(40vw, 15rem);
  width: auto;
  &:first-child {
    margin-inline-start: 1.5rem;
  }
  &:last-child {
    margin-inline-end: 1.5rem;
  }
  @media ${mq(sizes.desktop)} {
    flex: 1 0 0;
    min-width: 28rem;
  }
  //sticky
  ${({ $sticky }) =>
    $sticky === true &&
    css`
      min-width: 0;
      padding-block-end: 1rem;
      pointer-events: all;
      @media ${mq(sizes.desktop)} {
        flex: 0 0 auto;
        min-width: 0;
        overflow: visible;
        padding-block-end: 0;
        &:first-child {
          margin-inline-start: 0;
        }
        &:last-child {
          margin-inline-end: 0;
        }
      }
    `}
`
export const StyledLink = styled(Link)<{
  $sticky?: boolean
  $active?: boolean
}>`
  ${({ theme }) => theme.textStyles.titleH7}
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  height: 100%;
  padding: 1rem;
  border-radius: 2rem;
  color: ${({ theme }) => theme.scheme.anchorsColor};
  background-color: ${({ theme }) => theme.scheme.anchorsFill};
  transition: color 300ms ease-in-out, background-color 300ms ease-in-out,
    box-shadow 200ms ease-in-out;
  cursor: var(--cursor--link);
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.scheme.anchorsColorHover};
    background-color: ${({ theme }) => theme.scheme.anchorsFillHover};
    outline: 0 none;
  }
  &:active {
    color: ${({ theme }) => theme.scheme.anchorsColorHover};
    background-color: ${({ theme }) => theme.scheme.anchorsFillHover};
  }
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.titleH6}
    flex-flow: row nowrap;
    gap: 2rem;
    justify-content: flex-start;
    padding: 1rem;
  }
  //sticky
  ${({ $sticky, $active, theme }) =>
    $sticky === true &&
    css`
      width: 6rem;
      height: 6rem;
      padding: 1rem;
      border-radius: 6rem;
      box-shadow: 0 1rem 3rem 0 rgba(0, 0, 0, 0.05);
      // states
      color: ${$active === true
        ? theme.scheme.anchorsFixedColorActive
        : theme.scheme.anchorsFixedColor};
      background-color: ${$active === true
        ? theme.scheme.anchorsFixedFillActive
        : theme.scheme.anchorsFixedFill};
      &[aria-current] {
        color: ${({ theme }) => theme.scheme.anchorsFixedColorActive};
        background-color: ${theme.scheme.anchorsFixedFillActive};
      }
      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 1rem 3rem 0 rgba(0, 0, 0, 0.1);
      }
      &:hover,
      &:focus {
        color: ${({ theme }) => theme.scheme.anchorsFixedColorHover};
        background-color: ${theme.scheme.anchorsFixedFillHover};
      }
      &:active {
        color: ${({ theme }) => theme.scheme.anchorsFixedColorActive};
        background-color: ${theme.scheme.anchorsFixedFillActive};
      }
      @media ${mq(sizes.desktop)} {
        ${theme.textStyles.titleH7}
        flex-flow: row-reverse nowrap;
        gap: 1rem;
        width: auto;
        min-width: 6rem;
        &:hover,
        &:focus {
          padding-inline-start: 1.5rem;
        }
      }
    `}
`
export const StyledPicture = styled(Picture)<{ $sticky?: boolean }>`
  // normal
  flex: 0 0 auto;
  border-radius: 1rem;
  overflow: hidden;
  &,
  & img {
    display: block;
  }
  //sticky
  ${({ $sticky }) =>
    $sticky === true &&
    css`
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      overflow: hidden;
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    `}
  @media ${mq(sizes.desktop)} {
  }
`
export const Label = styled.span<{ $sticky?: boolean }>`
  // normal
  text-align: center;
  @media ${mq(sizes.desktop)} {
    text-align: left;
  }
  // sticky
  ${({ $sticky }) =>
    $sticky === true
      ? css`
          display: none;
          @media ${mq(sizes.desktop)} {
            display: none;
            text-align: right;
            ${StyledLink}:hover &,
            ${StyledLink}:focus & {
              display: block;
            }
          }
        `
      : null}
`

export const ScrollButton = styled.button<{ $direction: 'right' | 'left' }>`
  position: absolute;
  top: 50%;
  right: ${({ $direction }) => ($direction === 'right' ? '3.5rem' : 'unset')};
  left: ${({ $direction }) => ($direction === 'left' ? '3.5rem' : 'unset')};
  transform: translateY(-50%);
  display: none;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  width: 6rem;
  height: 6rem;
  border-radius: 2.4rem;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);

  @media ${mq(sizes.desktop)} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ScrollIcon = styled(Icon)`
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.arapawa};
`
