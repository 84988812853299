import React, { FC } from 'react';
import cx from 'classnames';
import { HtmlProps } from '../../atoms/Html';
import { ActionButtonProps } from '../../atoms/ActionButton';
import { Icons } from '../../atoms/Icon';
import { ActionButtonVariant } from '../../atoms/ActionButton/styled';
import * as SC from './styled';
import { Variant } from './types';
export type ContactUsBlockProps = MinimalSeoBlock & {
  className?: string;
  title?: string;
  data?: {
    variant?: Variant;
    title?: string;
    actionButtonProps?: ActionButtonProps;
    entries: {
      htmlProps?: HtmlProps;
      note?: string;
    }[];
  }[];
};
const ContactUsBlock: FC<ContactUsBlockProps> = props => {
  const {
    className,
    htmlTag,
    title,
    htmlTitleTag,
    data
  } = props;
  return data && data.length > 0 ? <SC.ContactUsBlock className={cx('ContactUsBlock', className)} as={htmlTag}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      <SC.List>
        {data.map((datum, index) => <SC.Item key={`ContactUsblock-item-${index}`}>
            {datum.title && <SC.Label>{datum.title}</SC.Label>}
            {datum.entries?.map(({
          htmlProps,
          note
        }, index2) => <SC.Info key={index2}>
                <SC.InfoMain {...htmlProps} />
                {note && <SC.InfoNote>{note}</SC.InfoNote>}
              </SC.Info>)}
            {datum.actionButtonProps && <SC.StyledActionButton iconProps={{
          icon: Icons.arrowRight,
          width: 24,
          height: 24
        }} iconPosition="right" variant={ActionButtonVariant.secondary} {...datum.actionButtonProps} />}
          </SC.Item>)}
      </SC.List>
    </SC.ContactUsBlock> : null;
};
export default ContactUsBlock;