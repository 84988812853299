import styled from 'styled-components'

import ActionButton from '../../atoms/ActionButton'
import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import Html from '../../atoms/Html'

export const BrandHero = styled.header`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;

  @media ${mq(sizes.tablet)} {
    padding-left: 0;
    padding-right: 0;
  }
  @media ${mq(sizes.desktop)} {
    text-align: left;
  }
`
export const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.pureWhite};
  padding-bottom: 4rem;
  border-top-left-radius: var(--radius-blocks--mobile);
  border-top-right-radius: var(--radius-blocks--mobile);
  overflow: hidden;
  @media ${mq(sizes.desktop)} {
    border-top-left-radius: var(--radius-blocks--desktop);
    border-top-right-radius: var(--radius-blocks--desktop);
  }
`
export const Banner = styled(Picture)`
  display: block;
  aspect-ratio: 1280 / 512;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
  &,
  & img {
    display: block;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const DesktopBanner = styled(Banner)`
  display: none;
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`
export const MobileBanner = styled(Banner)`
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`

export const ImagePlaceholder = styled.div`
  @media ${mq(sizes.tablet)} {
    height: 21rem;
    width: 100%;
  }
`
export const Bezel = styled.svg`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 4rem;
  transform: translateY(-100%);
  fill: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.mobile)} {
    height: 6rem;
  }
  @media ${mq(sizes.tablet)} {
    height: 9rem;
  }
`
export const Wrapper = styled.div`
  position: relative;
  @media ${mq(sizes.desktop)} {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 20rem 10rem 5rem auto; // space dealt with in empty rows because...
    grid-gap: 0 6rem;
    align-items: flex-start;
    margin-top: -16rem;
    padding: 0 5rem;
  }
`
export const Logo = styled(Picture)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11rem;
  height: 11rem;
  padding: 2rem;
  margin-top: -3rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius-blocks--mobile);
  background: ${({ theme }) => theme.colors.pureWhite};
  & img {
    width: 100%;
  }
  @media ${mq(sizes.mobile)} {
    margin-top: -5rem;
  }
  @media ${mq(sizes.desktop)} {
    width: 30rem;
    height: 30rem;
    padding: 5rem;
    margin-top: 0;
    grid-column: 2 / 3;
    grid-row: 1 / span 2;
  }
`
export const Content = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  &:not(:first-child) {
    margin-top: 3rem;
  }
  @media ${mq(sizes.desktop)} {
    grid-column: 1 / 2;
    grid-row: 2 / span 3;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    &,
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`
export const Header = styled.div`
  margin: 0;
  &:first-child {
    margin-top: 2rem;
  }
`
export const Title = styled.h1`
  margin: 0;
  ${({ theme }) => theme.textStyles.titleH3}
  text-transform: uppercase;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.cornflowerBlue};
  @media ${mq(sizes.scoping)} {
    ${({ theme }) => theme.textStyles.titleH1}
  }
`
export const Subtitle = styled.h2`
  ${({ theme }) => theme.textStyles.titleH4}
  margin: 0;
  display: block;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.arapawa};
  &:not(:first-child) {
    margin-top: 2rem;
  }
  @media ${mq(sizes.desktop)} {
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
  @media ${mq(sizes.scoping)} {
    ${({ theme }) => theme.textStyles.titleH2}
  }
`
export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.textLarge}
  margin: 0;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.bismark};
  &:not(:first-child) {
    margin-top: 2rem;
  }
  & > * {
    margin: 0;
  }
  & > :not(:first-child) {
    margin-top: 2rem;
  }
  @media ${mq(sizes.scoping)} {
    ${({ theme }) => theme.textStyles.textXLarge}
  }
`
export const Actions = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  &:not(:first-child) {
    margin-top: 3rem;
  }
  & > :not(:first-child) {
    margin-top: 2rem;
  }
  @media ${mq(sizes.desktop)} {
    grid-column: 2 / 3;
    grid-row: 4 / span 1;
    &,
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`
export const Action = styled(ActionButton)``
