import { SoloPage } from '../../graphql/generated/api-graphql'
import { wediaImagesTransform } from '../Common/wedia'
import { SoloHeroProps } from '../../components/molecules/SoloHero'

const useSoloHero = (data?: SoloPage): SoloHeroProps => {
  return {
    banner: {
      images: wediaImagesTransform(data?.headerImage),
      alt: data?.headerImage?.alt ?? ' ',
      maxWidth: 3200,
      width: data?.headerImage?.variations?.[0].width ?? undefined,
      height: data?.headerImage?.variations?.[0].height ?? undefined,
      withHD: true,
      withLazyLoading: false,
    },
    mobileBanner: data?.headerMobileImage
      ? {
          images: wediaImagesTransform(data.headerMobileImage),
          alt: data.headerMobileImage?.alt ?? ' ',
          maxWidth: 2000,
          width: data?.headerMobileImage?.variations?.[0].width ?? undefined,
          height: data?.headerMobileImage?.variations?.[0].height ?? undefined,
          withHD: true,
          withLazyLoading: false,
        }
      : undefined,
    logo: {
      images: wediaImagesTransform(
        data?.brand?.soloLogo ?? data?.brand?.pictureLogo
      ),
      alt:
        data?.brand?.soloLogo?.alt ??
        data?.brand?.pictureLogo?.alt ??
        data?.brand?.name ??
        ' ',
      maxWidth: 540,
      width: 270,
      height: 200,
      withHD: true,
      withLazyLoading: false,
    },
  }
}

export default useSoloHero
