import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import type { FooterProps } from '../../components/molecules/Footer';
import type { HeaderProps } from '../../components/molecules/Header';
import type { ProductPopinProps } from '../../components/molecules/ProductPopin';
import SoloNav, { SoloNavProps } from '../../components/molecules/SoloNav';
import SoloHero, { SoloHeroProps } from '../../components/molecules/SoloHero';
import SoloAnchors, { SoloAnchorsProps } from '../../components/molecules/SoloAnchors';
import SoloBanner, { SoloBannerProps } from '../../components/molecules/SoloBanner';
import { ContactUsBlockProps } from '../../components/molecules/ContactUsBlock';
import SoloSocials, { SoloSocialsProps } from '../../components/molecules/SoloSocials';
import SoloContentAnchors, { SoloContentAnchorsProps } from '../../components/molecules/SoloContentAnchors';
import SoloHistoryAnchors, { SoloHistoryAnchorsProps } from '../../components/molecules/SoloHistoryAnchors';
import { AdProps } from '../../components/molecules/Ad';
import * as SC from './styled';
export type SoloLayoutProps = Pick<MinimalSeoBlock, 'htmlTitleTag'> & {
  className?: string;
  idContent?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  headerProps?: HeaderProps;
  footerProps?: FooterProps;
  soloNavProps?: SoloNavProps;
  soloContentAnchorsProps?: SoloContentAnchorsProps;
  soloHistoryAnchorsProps?: SoloHistoryAnchorsProps;
  soloHeroProps?: SoloHeroProps;
  soloAnchorsProps?: SoloAnchorsProps;
  soloBannerProps?: SoloBannerProps;
  contactUsProps?: ContactUsBlockProps;
  soloSocialsProps?: SoloSocialsProps;
  title?: string;
  children?: React.ReactNode;
  productPopinProps?: ProductPopinProps;
  withMobileModal?: boolean;
  topBlocks?: React.ReactNode;
  adProps?: AdProps;
};
const SoloLayout: FC<SoloLayoutProps> = ({
  className,
  idContent = 'Content',
  soloNavProps,
  soloContentAnchorsProps,
  soloHistoryAnchorsProps,
  soloHeroProps,
  soloAnchorsProps,
  soloBannerProps,
  contactUsProps,
  soloSocialsProps,
  title,
  htmlTitleTag,
  children,
  productPopinProps,
  withMobileModal,
  topBlocks,
  adProps,
  header,
  footer
}) => {
  const ProductPopin = productPopinProps?.popin?.isOpen && dynamic(() => import('../../components/molecules/ProductPopin'));
  return <>
      <SC.Layout className={className} $adBackground={adProps?.background}>
        {header}
        {/* {adProps && <SC.StyledAd {...adProps} />} */}
        <SC.Main id={idContent} $withMobileModal={withMobileModal}>
          <SC.Top>
            {soloHeroProps && <SoloHero {...soloHeroProps} />}
            {soloNavProps && <SoloNav {...soloNavProps} />}
            {soloAnchorsProps && <SoloAnchors offset={78} {...soloAnchorsProps} />}
            {soloBannerProps && <SoloBanner {...soloBannerProps} />}
          </SC.Top>
          {soloHistoryAnchorsProps && <SoloHistoryAnchors {...soloHistoryAnchorsProps} />}
          {topBlocks}
          {(children || title || soloContentAnchorsProps) && <SC.Content>
              {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
              {soloContentAnchorsProps && <SoloContentAnchors {...soloContentAnchorsProps} />}
              {children}
            </SC.Content>}
          {(contactUsProps || soloSocialsProps) && <SC.Bottom>
              {contactUsProps && <SC.StyledContact {...contactUsProps} />}
              {soloSocialsProps && <SoloSocials {...soloSocialsProps} />}
            </SC.Bottom>}
        </SC.Main>
        {footer}
      </SC.Layout>
      {productPopinProps && ProductPopin && <ProductPopin {...productPopinProps} />}
    </>;
};
export default SoloLayout;