import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import AccessibleTitle from '../../atoms/AccessibleTitle'
import IconButton from '../../atoms/IconButton'
import Icon from '../../atoms/Icon'

import { SoloHeroShapeStyle } from './types'

export const Container = styled.header<{ $isGeneric?: boolean }>`
  position: relative;
  padding-bottom: 2rem;
  overflow: hidden;
  // to prevent the bezel from looking cut off
  &::after {
    content: '';
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    z-index: 3;
    height: 0.1rem;
    background: white;
  }
`

export const Title = styled(AccessibleTitle)``

export const Images = styled.div`
  position: relative;
  z-index: 1;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  @media ${mq(sizes.tablet)} {
    aspect-ratio: 1440 / 576;
  }
`

const imageStyles = css`
  height: 100%;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Banner = styled(Picture)<{ $withMobileALternative?: boolean }>`
  ${imageStyles}
  display: ${({ $withMobileALternative }) =>
    $withMobileALternative ? 'none' : 'block'};
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`

export const MobileBanner = styled(Picture)`
  ${imageStyles}
  display: block;
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`

export const Logo = styled(Picture)`
  position: absolute;
  bottom: ${({ theme }) =>
    theme.scheme.heroShapeStyle === SoloHeroShapeStyle.FullRound ||
    theme.scheme.heroShapeStyle === SoloHeroShapeStyle.WithAngle
      ? '0'
      : theme.scheme.heroShapeStyle === SoloHeroShapeStyle.lion
      ? '2.5rem'
      : '1.5rem'};
  left: 50%;
  z-index: 5;
  transform: translateX(-50%);
  aspect-ratio: ${({ theme }) =>
    theme.scheme.heroShapeStyle === SoloHeroShapeStyle.FullRound
      ? '200 / 200'
      : '270 / 200'};
  width: ${({ theme }) =>
    theme.scheme.heroShapeStyle === SoloHeroShapeStyle.FullRound
      ? 'min(20vw, 20rem)'
      : theme.scheme.heroShapeStyle === SoloHeroShapeStyle.WithAngle
      ? 'min(35vw, 36rem)'
      : 'min(26vw, 27rem)'};
  transform: ${({ theme }) =>
    theme.scheme.heroShapeStyle === SoloHeroShapeStyle.FullRound
      ? 'translateX(-50%) translateY(calc(0px - min(20vw, 120px) + 50%))'
      : 'translateX(-50%) translateY(15%)'};
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom center;
  }
`

export const Like = styled(IconButton)`
  position: absolute;
  bottom: 10%;
  right: 1.5rem;
  z-index: 4;
  @media ${mq(sizes.tablet)} {
    bottom: 12%;
    right: 3.5rem;
  }
`

export const Shape = styled(Icon)`
  position: absolute;
  bottom: ${({ theme }) =>
    theme.scheme.heroShapeStyle === SoloHeroShapeStyle.FullRound
      ? '0'
      : '0.8rem'};
  left: 50%;
  z-index: 4;
  transform: ${({ theme }) =>
    theme.scheme.heroShapeStyle === SoloHeroShapeStyle.FullRound
      ? 'translateX(-50%) translateY(calc(0px - min(20vw, 120px) + 50%))'
      : 'translateX(-50%)'};
  width: ${({ theme }) =>
    theme.scheme.heroShapeStyle === SoloHeroShapeStyle.FullRound
      ? 'min(31vw, 28rem)'
      : theme.scheme.heroShapeStyle === SoloHeroShapeStyle.WithAngle
      ? 'min(50vw, 50.8rem)'
      : 'min(56vw, 55rem)'};
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Bezel = styled.svg`
  z-index: 2;
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  height: auto;
`
