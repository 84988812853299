import { useTranslation } from 'react-i18next'
import tracking from 'src/tracking'
import { enumBrandSocialPicto } from 'src/helpers/EnumHelpers'

import { Brand } from '../../graphql/generated/api-graphql'
import { wediaImagesTransform } from '../Common/wedia'
import { BrandHeroProps } from '../../components/molecules/BrandHero'
import { Icons } from '../../components/atoms/Icon'
import { ActionButtonVariant } from '../../components/atoms/ActionButton/styled'

const useBrandHero = (data?: Brand): BrandHeroProps => {
  const { t } = useTranslation()

  return {
    title: data?.name,
    imageProps: {
      maxWidth: 1280,
      width: 1280,
      height: 512,
      withHD: true,
      withLazyLoading: false,
      alt: data?.name ?? data?.pictureHeader?.alt ?? '',
      images: wediaImagesTransform(data?.pictureHeader),
      placeholder: !data?.pictureHeader,
    },
    mobileImageProps: {
      maxWidth: 370,
      width: 370,
      height: 200,
      withHD: true,
      withLazyLoading: false,
      images: data?.pictureHeader
        ? wediaImagesTransform(data.pictureHeader)
        : undefined,
      alt: data?.name ?? data?.pictureHeader?.alt ?? '',
    },
    brandLogoProps: data?.pictureLogo
      ? {
          maxWidth: 200,
          width: 200,
          height: 200,
          withHD: true,
          withLazyLoading: false,
          alt: data?.name ?? data?.pictureLogo.alt ?? '',
          images: wediaImagesTransform(data.pictureLogo),
        }
      : undefined,
    subtitle: data?.introductionTitle,
    htmlProps: {
      text: data?.introductionText ?? '',
    },
    actionButtonsProps: [
      data?.socialUrl
        ? {
            label: t('learn_more'),
            iconPosition: 'left',
            iconProps: enumBrandSocialPicto(data?.socialPicto ?? '') && {
              icon:
                enumBrandSocialPicto(data?.socialPicto ?? '') ??
                Icons.socialFacebook,
            },
            variant: ActionButtonVariant.secondary,
            href: data?.socialUrl ? data?.socialUrl : undefined,
            onClick: () =>
              tracking.brand.details('', data?.introductionTitle ?? ''),
          }
        : {},
    ],
  }
}

export default useBrandHero
