import React, { FC } from 'react';
import cx from 'classnames';
import Head from 'next/head';
import { jsonLdScriptProps } from 'react-schemaorg';
import { VideoObject } from 'schema-dts';
import { VideoProps } from '../../atoms/Video';
import { ActionButtonProps } from '../../atoms/ActionButton';
import { HtmlProps } from '../../atoms/Html';
import * as SC from './styled';
import { VideoBlockPosition } from './types';
export type VideoBlockProps = MinimalSeoBlock & {
  className?: string;
  videoProps: VideoProps;
  title?: string;
  htmlProps?: HtmlProps;
  actionButtonProps?: ActionButtonProps;
  text?: string;
  videoPosition?: VideoBlockPosition;
};
const VideoBlock: FC<VideoBlockProps> = props => {
  const {
    className,
    htmlTag,
    videoProps,
    title,
    htmlTitleTag,
    htmlProps,
    actionButtonProps,
    text,
    videoPosition
  } = props;
  const thumbnailUrl = videoProps.poster ? videoProps.poster?.match(/^http(s*):/) ? videoProps.poster : videoProps.jsonLdBasePath && videoProps.jsonLdBasePath + videoProps.poster : videoProps?.sources?.[0]?.src;
  return <SC.VideoBlock className={cx('VideoBlock', className)} as={htmlTag}>
      <Head>
        <script {...jsonLdScriptProps<VideoObject>({
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        name: title ?? 'Video',
        description: text,
        uploadDate: videoProps?.jsonLdInfo?.uploadDate ?? new Date().toISOString(),
        thumbnailUrl,
        contentUrl: videoProps?.sources?.[0]?.src
      })} />
      </Head>
      <SC.StyledVideo {...videoProps} $videoPosition={videoPosition} disableJsonLd={true} />
      {(title || htmlProps || actionButtonProps) && <SC.Content $videoPosition={videoPosition}>
          {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
          {htmlProps && <SC.Text {...htmlProps} />}
          {text && <SC.TextVideo>{text}</SC.TextVideo>}
          {actionButtonProps && <SC.Action {...actionButtonProps} />}
        </SC.Content>}
    </SC.VideoBlock>;
};
export default VideoBlock;