import React, { FC } from 'react';
import cx from 'classnames';
import { ActionButtonProps } from '../../atoms/ActionButton';
import { PictureProps } from '../../atoms/Picture';
import { HtmlProps } from '../../atoms/Html';
import * as SC from './styled';
export type BrandHeroProps = {
  className?: string;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  htmlProps?: HtmlProps;
  imageProps?: PictureProps;
  mobileImageProps?: PictureProps;
  brandLogoProps?: PictureProps;
  actionButtonsProps?: ActionButtonProps[];
};
const BrandHero: FC<BrandHeroProps> = props => {
  const {
    className,
    title,
    subtitle,
    htmlProps,
    imageProps,
    mobileImageProps,
    brandLogoProps,
    actionButtonsProps
  } = props;
  return <SC.BrandHero className={cx('BrandHero', className)}>
      <SC.Container>
        {!mobileImageProps && <SC.Banner {...imageProps} />}
        {mobileImageProps && <>
            <SC.DesktopBanner {...imageProps} />
            <SC.MobileBanner {...mobileImageProps} />
          </>}
        <SC.Bezel xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 335 10">
          <path d="M0 0v10h168.303C99.7883 9.9955 38.8536 6.07873 0 0zm168.537 10H335V.283797C296.078 6.20036 235.98 9.9956 168.537 10z" />
        </SC.Bezel>
        <SC.Wrapper>
          {brandLogoProps && <SC.Logo {...brandLogoProps} />}
          {(title || subtitle || htmlProps) && <SC.Content>
              {(title || subtitle) && <SC.Header>
                  {title && <SC.Title>{title}</SC.Title>}
                  {title && subtitle && ' '}
                  {subtitle && <SC.Subtitle>{subtitle}</SC.Subtitle>}
                </SC.Header>}
              {htmlProps && <SC.Text {...htmlProps} />}
            </SC.Content>}
          {actionButtonsProps && actionButtonsProps.length > 0 && <SC.Actions>
              {actionButtonsProps.map((action, index) => <React.Fragment key={`BrandHero-action-${index}`}>
                  {(action.href || action.onClick) && <SC.Action {...action} />}
                </React.Fragment>)}
            </SC.Actions>}
        </SC.Wrapper>
      </SC.Container>
    </SC.BrandHero>;
};
export default BrandHero;