import React, { FC } from 'react';
import { RoundedCorners as BaggyRoundedCorners } from '../../components/atoms/Baggy/types';
import { BrandHeroProps } from '../../components/molecules/BrandHero';
import { MainLayoutProps } from '../../layouts/MainLayout';
import { colors } from '../../theme';
import CardsBlock, { CardsBlockProps } from '../../components/molecules/CardsBlock';
import { CouponCardsBlockProps } from '../../components/molecules/CouponCardsBlock';
import { RelatedProductsBlockProps } from '../../components/molecules/RelatedProductsBlock';
import { ContactUsBlockProps } from '../../components/molecules/ContactUsBlock';
import { HighlightBlockProps } from '../../components/molecules/HighlightBlock';
import { VideoBlockProps } from '../../components/molecules/VideoBlock';
import { Variant as CouponsCardBlockVariant } from '../../components/molecules/CouponCardsBlock/types';
import { PushFormatsProps } from '../../components/molecules/PushFormats';
import * as SC from './styled';
export type BrandTemplateProps = MainLayoutProps & {
  brandHeroProps?: BrandHeroProps;
  highlightProps?: HighlightBlockProps;
  videoProps?: VideoBlockProps;
  newsProps?: CardsBlockProps;
  toothpickProps?: HighlightBlockProps;
  couponsProps?: CouponCardsBlockProps;
  pushFormatsProps?: PushFormatsProps[];
  relatedProps?: RelatedProductsBlockProps;
  recipeProps?: CardsBlockProps;
  contactUsProps?: ContactUsBlockProps;
};
const RecipesTemplate: FC<BrandTemplateProps> = ({
  brandHeroProps,
  highlightProps,
  videoProps,
  newsProps,
  toothpickProps,
  couponsProps,
  pushFormatsProps,
  relatedProps,
  recipeProps,
  contactUsProps,
  ...layoutProps
}) => {
  return <>
      <SC.Layout {...layoutProps}>
        {brandHeroProps && <SC.StyledBrandHero {...brandHeroProps} />}
        {(highlightProps || videoProps) && <SC.TopZone isRoundedOnMobile roundedCorners={BaggyRoundedCorners.Bottom} backgroundColor={colors.chablis}>
            {highlightProps && <SC.Highlight {...highlightProps} />}
            {videoProps && <SC.Video {...videoProps} />}
          </SC.TopZone>}
        {newsProps && <CardsBlock {...newsProps} />}
        {toothpickProps && <SC.Toothpick {...toothpickProps} />}
        {couponsProps && <SC.Coupons {...couponsProps} variant={CouponsCardBlockVariant.Default} />}
        {pushFormatsProps && pushFormatsProps.length > 0 && <SC.PushFormats>
            {pushFormatsProps.map((pushFormatProps, index) => <SC.PushFormat key={index} {...pushFormatProps} />)}
          </SC.PushFormats>}
        {(relatedProps || recipeProps || contactUsProps) && <SC.BottomZone roundedCorners={BaggyRoundedCorners.Both} backgroundColor={colors.chablis}>
            {relatedProps && <SC.Related {...relatedProps} />}
            {recipeProps && <SC.Recipes {...recipeProps} />}
            {contactUsProps && <SC.ContactUs {...contactUsProps} />}
          </SC.BottomZone>}
      </SC.Layout>
    </>;
};
export default RecipesTemplate;