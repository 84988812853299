import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Brand, Format, PageTemplate } from '../../graphql/generated/api-graphql';
import productPopinTransform from '../ProductPopin/transform';
import Router, { routes } from '../../routes/Router';
import highlightTransformer from '../Common/highlightTransformer';
import { VideoBlockProps } from '../../components/molecules/VideoBlock';
import { PushFormatsProps } from '../../components/molecules/PushFormats';
import { ProductPopinProps } from '../../components/molecules/ProductPopin';
import { adPropsTransformer } from '../Common/adPropsTransformer';
import BrandTemplate from '../../templates/BrandTemplate';
import type { BrandPageProps } from '../../pages/_brand/[slug]';
import HeaderWrapper from '../../relay/Header/HeaderWrapper';
import FooterWrapper from '../../relay/Footer/FooterWrapper';
import BreadcrumbWrapper from '../Breadcrumb/BreadcrumbWrapper';
import { brandContactTransformer } from './brandContactTranformer';
import { brandPushFormatsTransformer } from './brandPushFormatsTransformer';
import { brandProductsTransformer } from './brandProductsTranformer';
import { brandArticlesTransformer } from './brandArticlesTranformer';
import { brandRecipesTransformer } from './brandRecipesTranformer';
import useBrandHero from './useBrandHero';
const RealBrandPage: FC<Omit<BrandPageProps, 'data'> & {
  data: Brand;
}> = ({
  data,
  tags,
  configuration
  // popinProps,
}) => {
  const {
    t
  } = useTranslation();
  const router = useRouter();

  // const [loadedFromSSRData, setLoadedFromSSRData] = useState(false)
  const [openOpin, setOpenPopin] = useState(false);
  const [format, setFormat] = useState<any>(null);
  const popinData = productPopinTransform({
    t,
    data: {
      format: format
    },
    displayMoreButton: false
  });
  const handleClose = useCallback(() => {
    setOpenPopin(false);
    setFormat(null);
    router.push(Router.getRouteUrl(routes.brand, {
      slug: data.slug
    }), undefined, {
      shallow: true
    });
  }, [data.slug, router]);
  const handleLoadOpenPopin = useCallback((product: Format) => {
    setFormat(product);
    setOpenPopin(true);
  }, []);

  // Disabled popin push
  // useEffect(() => {
  //   if (popinProps && !loadedFromSSRData) {
  //     setLoadedFromSSRData(true)
  //     handleLoadOpenPopin(popinProps)
  //   }
  // }, [
  //   data?.cheeses,
  //   handleLoadOpenPopin,
  //   loadedFromSSRData,
  //   popinProps,
  //   router.query?.format,
  // ])

  const breadcrumbEntries = useMemo(() => [{
    label: configuration?.pagesByTemplate?.[PageTemplate.HpCheeses]?.title ?? t('breadcrumb_brands'),
    href: configuration?.pagesByTemplate?.[PageTemplate.HpCheeses]?.slug
  }, {
    label: configuration?.pagesByTemplate?.[PageTemplate.ListCheeses]?.title ?? t('breadcrumb_brands'),
    href: configuration?.pagesByTemplate?.[PageTemplate.ListCheeses]?.slug
  }, {
    label: data?.name
  }], [configuration?.pagesByTemplate, data?.name, t]);
  const brandHeroProps = useBrandHero(data);
  const highlightProps = highlightTransformer(t, data, format => {
    handleLoadOpenPopin(format);
    router.push(Router.getRouteUrl(routes.brand, {
      slug: data.slug,
      format: format.slug
    }), undefined, {
      shallow: true
    });
  });
  const recipeProps = brandRecipesTransformer(t, data, tags);
  const newsProps = brandArticlesTransformer(t, data);
  const videoProps: VideoBlockProps | undefined = undefined; // todo
  const handleFormatClick = useCallback((format: Format) => {
    handleLoadOpenPopin(format);
    router.push(Router.getRouteUrl(routes.brand, {
      slug: data.slug,
      format: format.slug
    }), undefined, {
      shallow: true
    });
  }, [data.slug, handleLoadOpenPopin, router]);
  const relatedProps = brandProductsTransformer(t, data, handleFormatClick);
  const pushFormatsProps: PushFormatsProps[] | undefined = brandPushFormatsTransformer(t, data, handleFormatClick);
  const contactUsProps = brandContactTransformer(t, data?.contacts?.[0]);

  // ------
  // popin
  const productPopinProps: ProductPopinProps | undefined = popinData ? {
    ...popinData,
    popin: {
      ...popinData.popin,
      isOpen: openOpin,
      closeHandler: handleClose
    }
  } : undefined;
  const adProps = adPropsTransformer(data, configuration);
  return <>
      <BrandTemplate idContent="Content" header={<HeaderWrapper data={configuration?.header} socials={configuration?.footer?.socialLinks} />} footer={<FooterWrapper data={configuration?.footer} />} breadcrumb={<BreadcrumbWrapper entries={breadcrumbEntries} />} brandHeroProps={brandHeroProps} productPopinProps={productPopinProps} highlightProps={highlightProps} newsProps={newsProps} recipeProps={recipeProps} contactUsProps={contactUsProps} videoProps={videoProps} relatedProps={relatedProps} adProps={adProps} pushFormatsProps={pushFormatsProps} />
    </>;
};
export default RealBrandPage;