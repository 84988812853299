import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import cx from 'classnames';
import { useDebounce } from 'usehooks-ts';
import { PictureProps } from '../../atoms/Picture';
import { LinkProps } from '../../atoms/Link';
import * as SC from './styled';
export type SoloAnchorsProps = MinimalSeoBlock & {
  className?: string;
  // use offset to account for other fixed elements on top of it
  offset?: number;
  title?: string;
  items?: {
    linkProps: LinkProps;
    pictureProps?: PictureProps;
    isCurrent?: boolean;
  }[];
};
const SoloAnchors: FC<SoloAnchorsProps> = props => {
  const {
    className,
    title,
    items,
    htmlTitleTag,
    offset = 0
  } = props;
  const [isScrollable, setScrollable] = useState(false);
  const [sticky, setSticky] = useState<boolean>(false);
  const [leftScrollEnd, setLeftScrollEnd] = useState<boolean>(true);
  const [rightScrollEnd, setRightScrollEnd] = useState<boolean>(false);
  const debouncedScrollable = useDebounce<boolean>(isScrollable, 10);
  const scrollRef = useRef<ScrollContainer>(null);
  const addScrollCursor = useCallback(() => {
    const element = scrollRef.current?.getElement();
    if (!element || !element?.children) return;
    const childrenWidth = Array.from(element.children).reduce((total, element) => {
      return total + element.clientWidth;
    }, 0);
    setScrollable(element.offsetWidth < childrenWidth);
  }, []);
  const ref = useRef<HTMLElement | null>(null);
  const toggleSticky = useCallback(() => {
    if (!window || !ref.current) return;
    const offsetTop = ref.current?.getBoundingClientRect()?.top;
    const height = ref.current?.clientHeight ?? 0;
    if (typeof offsetTop === undefined) return;
    if (offsetTop + height - offset < 0) {
      ref.current.style.minHeight = `${height}px`;
      setSticky(true);
    } else {
      ref.current.style.removeProperty('min-height');
      setSticky(false);
    }
  }, [offset]);
  useEffect(() => {
    const onResize = () => {
      addScrollCursor();
    };
    const onScroll = () => {
      toggleSticky();
      addScrollCursor();
    };
    onResize();
    onScroll();
    window && window.addEventListener('resize', onResize);
    window && window.addEventListener('scroll', onScroll);
    return () => {
      window && window.removeEventListener('resize', onResize);
      window && window.removeEventListener('scroll', onScroll);
    };
  }, [addScrollCursor, toggleSticky]);
  useEffect(() => {
    if (scrollRef.current) {
      const element = scrollRef.current.getElement();
      if (element) {
        element.addEventListener('scroll', () => {
          setLeftScrollEnd(element.scrollLeft === 0);
          setRightScrollEnd(element.scrollLeft + element.clientWidth === element.scrollWidth);
        });
      }
    }
  }, []);
  const triggerScroll = useCallback((direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const element = scrollRef.current.getElement();
      element && element.scrollTo({
        left: direction === 'left' ? element.scrollLeft - 300 : element.scrollLeft + 300,
        behavior: 'smooth'
      });
    }
  }, []);
  useEffect(() => {
    console.log('XXX', isScrollable);
  }, [isScrollable]);
  return items && items.length > 0 ? <SC.Container className={cx('SoloAnchors', className)} ref={ref}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      <SC.Sticky $sticky={sticky} $offset={offset}>
        <SC.Scroller $scrollable={debouncedScrollable} ref={scrollRef} vertical={false} $sticky={sticky}>
          {isScrollable && !leftScrollEnd && !sticky && <SC.ScrollButton onClick={() => triggerScroll('left')} $direction="left">
              <SC.ScrollIcon icon="chevronLeft" />
            </SC.ScrollButton>}
          <SC.List $sticky={sticky}>
            {items.map((item, index) => <SC.Item key={`SoloAnchors-${index}`} $sticky={sticky}>
                <SC.StyledLink aria-label={item.linkProps.label} {...item.linkProps} $sticky={sticky} $active={item.isCurrent}>
                  {item.pictureProps && <SC.StyledPicture {...item.pictureProps} $sticky={sticky} />}
                  {item.linkProps.label && <SC.Label $sticky={sticky}>{item.linkProps.label}</SC.Label>}
                </SC.StyledLink>
              </SC.Item>)}
          </SC.List>
          {isScrollable && !rightScrollEnd && !sticky && <SC.ScrollButton onClick={() => triggerScroll('right')} $direction="right">
              <SC.ScrollIcon icon="chevronRight" />
            </SC.ScrollButton>}
        </SC.Scroller>
      </SC.Sticky>
    </SC.Container> : null;
};
export default SoloAnchors;