import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { LinkProps } from '../../atoms/Link';
import * as SC from './styled';
export type SoloHistoryAnchorsProps = {
  className?: string;
  items?: {
    linkProps: LinkProps;
    isCurrent?: boolean;
  }[];
  offset?: number;
};
const SoloHistoryAnchors: FC<SoloHistoryAnchorsProps> = props => {
  const {
    className,
    items,
    offset = 0
  } = props;
  const [sticky, setSticky] = useState<boolean>(false);
  const ref = useRef<HTMLElement | null>(null);
  const toggleSticky = useCallback(() => {
    if (!window || !ref.current) return;
    const offsetTop = ref.current?.getBoundingClientRect()?.top;
    if (typeof offsetTop === undefined) return;
    if (offsetTop - window.innerHeight / 6 - offset < 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, [offset]);
  useEffect(() => {
    const onScroll = () => {
      toggleSticky();
    };
    onScroll();
    window && window.addEventListener('scroll', onScroll);
    return () => {
      window && window.removeEventListener('scroll', onScroll);
    };
  }, [toggleSticky]);
  return items && items.length > 0 ? <SC.Container className={cx('SoloHistoryAnchors', className)} ref={ref}>
      <SC.List $offset={offset} $sticky={sticky}>
        {items.map((item, index) => <SC.Item key={`SoloAnchors-${index}`}>
            <SC.StyledLink aria-label={item.linkProps.label} {...item.linkProps} $active={item.isCurrent}>
              {item.linkProps.label}
            </SC.StyledLink>
          </SC.Item>)}
      </SC.List>
    </SC.Container> : null;
};
export default SoloHistoryAnchors;